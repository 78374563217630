import {initCart} from "./initCart";
import {CheckoutBillingDetails} from "../_classes";
import {initSubscriptions} from "./initSubscriptions";
import {ResponsiveRearrange, matchLocationPath} from "../sf-utils";

export function initCheckout() {
	initCart();

	if (matchLocationPath("/checkout/")) {
		new CheckoutBillingDetails({
			billing_first_name: ["hasValue"],
			billing_last_name: ["hasValue"],
			billing_email: ["hasValue", "isEmail"],
			billing_company: ["hasValue"],
			customer_reference: ["hasValue"],
			billing_country: [],
			billing_address_1: ["hasValue"],
			billing_address_2: ["hasValue"],
			billing_city: ["hasValue"],
			billing_state: ["hasValue"],
			billing_postcode: ["hasValue"],
			tax_id: ["hasCompletedTaxField"]
		});
	}

	// if #place_order button exists...
	if ($("#place_order").length) {
		new ResponsiveRearrange({
			targets: "place_order", // ...create a new ResponsiveRearrange instance to move it to...
			breakpoints: [{respondToMin: 750, destinations: "sfwc-checkout-product-details"}] // ...#sfwc-checkout-product-details when the screen width is <750px
		});
	}

	// initSubscriptions(); <-- ready to use, but not used anywhere yet
}
