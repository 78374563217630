import {initAddToCart} from "./initAddToCart"; // Updated import statement
import {initFAQ} from "./initFAQ";
import {initGuides} from "./initGuides";
import {initHome} from "./initHome";
import {initStory} from "./initStory";
import {initAdwordsHistory} from "./initAdwordsHistory";
import {initContactForms} from "./initContactForms";
import {initCarousel} from "./initCarousel";
import {matchLocationPath, isHomePage} from "../sf-utils";

export function initMain() {
	//PAGES ON MAIN SITE:
	if (isHomePage()) {
		initHome();
	}

	if (matchLocationPath("/our-story")) {
		initStory();
	}

	if ($("#sf-carousel").length) {
		initCarousel();
	}

	// if on guides pages
	if (matchLocationPath(["/seo-spider/user-guide/", "/log-file-analyser/user-guide/"], false)) {
		initGuides();
	}

	initFAQ();

	if (matchLocationPath(["/seo-spider/licence/", "/log-file-analyser/licence/", "/cart/"], false)) {
		initAddToCart(); // init add to cart for licence and cart pages only
	}

	// if on /adwords-history/ page
	if (matchLocationPath("/adwords-history/")) {
		initAdwordsHistory();
	}

	initContactForms();
}
